var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"CJ1VuYWBmag-sH3TzJnQ-"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN || 'https://78f33f21681748ad9e8083a5ec79a734@sentry.nobo.ovh/9',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  environment: process.env.ENV || 'development',
  enabled: ['production', 'staging'].includes(process.env.ENV || process.env.NODE_ENV),
  ignoreErrors: ["Cannot set headers after they are sent to the client"],
  // Called for transaction events
  beforeSendTransaction(event) {
    // Modify or drop the event here
    if (event.transaction === "/api/_/[op]") {
      // Don't send the event to Sentry
      return null;
    }
    return event;
  },
});
