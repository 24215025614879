export const CUSTOMER = {
  ERROR: 'CUSTOMER_CALL_ERROR',
  SUCCESS: 'CUSTOMER_CALL_SUCCESS',
  LOADING: 'CUSTOMER_CALL_LOADING',
  RESET: 'CUSTOMER_CALL_RESET',
};

export const ALLCUSTOMERS = {
  ERROR: 'ALLCUSTOMERS_CALL_ERROR',
  SUCCESS: 'ALLCUSTOMERS_CALL_SUCCESS',
  LOADING: 'ALLCUSTOMER_SCALL_LOADING',
  RESET: 'ALLCUSTOMERS_CALL_RESET',
};

export const PAYMENT = {
  ERROR: 'PAYMENT_CALL_ERROR',
  SUCCESS: 'PAYMENT_CALL_SUCCESS',
  LOADING: 'PAYMENT_CALL_LOADING',
  RESET: 'PAYMENT_CALL_RESET',
  ADD_CESU_POT: 'ADD_CESU_POT',
};

export const CUSTOMER_PROFIL = {
  ERROR: 'CUSTOMER_PROFIL_CALL_ERROR',
  SUCCESS: 'CUSTOMER_PROFIL_CALL_SUCCESS',
  LOADING: 'CUSTOMER_PROFIL_CALL_LOADING',
  RESET: 'CUSTOMER_PROFIL_CALL_RESET',
};

export const MISSION_END = {
  ERROR: 'MISSION_END_GET_ERROR',
  SUCCESS: 'MISSION_END_GET_SUCCESS',
  LOADING: 'MISSION_END_GET_LOADING',
};

export const MISSION_ONGO = {
  ERROR: 'MISSION_ONGO_GET_ERROR',
  SUCCESS: 'MISSION_ONGO_GET_SUCCESS',
  LOADING: 'MISSION_ONGO_GET_LOADING',
};

export const EVENT = {
  ERROR: 'EVENT_GET_ERROR',
  SUCCESS: 'EVENT_GET_SUCCESS',
  LOADING: 'EVENT_GET_LOADING',
};

export const PROVIDER = {
  UPDATE_PASSWORD: 'PROVIDER_UPDATE_PASSWORD',
};

export const NOTIFICATION = {
  SET: 'SET',
};
