import React from 'react';
import App, { Container } from 'next/app';
import Head from 'next/head';
import { MuiThemeProvider } from '@material-ui/core/styles';
import JssProvider from 'react-jss/lib/JssProvider';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ToastContainer } from 'react-toastify';
import getPageContext from '../src/getPageContext';
import { wrapper } from '../redux/store';
import 'react-toastify/dist/ReactToastify.css';
import '../css/app.css';
import { getApiToken } from "../component/utils/checkToken";
import { getConf } from "../component/utils/conf";
import moment from 'moment';
import 'moment/locale/fr';

moment.locale('fr');
class MyApp extends App {
  constructor(props) {
    super(props);
    this.pageContext = getPageContext();
  }

  static async getInitialProps({ Component, router, ctx }) {
    const { req } = ctx;
    const apiToken = await getApiToken(req);
    const googleApiKey = await getConf('GOOGLE_AUTOCOMPLETION_API_KEY', apiToken);
    let pageProps = {};

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }
    return {
      pageProps: { ...pageProps },
      googleApiKey,
    };
  }

  render() {
    const { Component, pageProps, googleApiKey} = this.props;

    return (
        <Container>
          <Head>
            <title>
              Nobo
            </title>
            <script
              src={`https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=geometry,places&region=FR`}
            />
          </Head>
          <JssProvider
            registry={this.pageContext.sheetsRegistry}
            generateClassName={this.pageContext.generateClassName}
          >
            <MuiThemeProvider
              theme={this.pageContext.theme}
              sheetsManager={this.pageContext.sheetsManager}
            >
              <div>
                <CssBaseline />
                <ToastContainer
                  position="bottom-right"
                  autoClose={3000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnVisibilityChange
                  draggable
                  pauseOnHover
                />
               <Component pageContext={this.pageContext} {...pageProps} />
              </div>
            </MuiThemeProvider>
          </JssProvider>
        </Container>
    );
  }
}

export default wrapper.withRedux(MyApp);
