import { CUSTOMER_PROFIL } from '../../component/action/actionList';

const initialState = {
  customerProfilData: null,
  error: '',
  isLoading: false,
};

export default function customerProfilReducer(state = initialState, action) {
  switch (action.type) {
    case CUSTOMER_PROFIL.SUCCESS:
      return {
        ...state,
        customerProfilData: action.customerProfilData,
      };
    case CUSTOMER_PROFIL.ERROR:
      return {
        ...state,
        error: action.error,
      };
    case CUSTOMER_PROFIL.LOADING:
      return {
        ...state,
        error: action.isLoading,
      };
    case CUSTOMER_PROFIL.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
