import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '@api';

export const fetchCustomersNews = createAsyncThunk<CustomerNews[]>(
  'customers/fetchCustomersNews',
  async () => {
    try {
      const { data: { data } } = await api.customer().news.get();
      return data;
    } catch (error: any) {
      throw (error?.response?.data?.message);
    }
  },
);

export const fetchCustomersSuggestions = createAsyncThunk<CustomerSuggestion[]>(
  'customers/fetchCustomersSuggestions',
  async () => {
    try {
      const { data: { data } } = await api.customer().suggestions();
      return data;
    } catch (error: any) {
      throw (error?.response?.data?.message);
    }
  },
);

export const deleteCustomerNews = createAsyncThunk<void, number>(
  'customers/deleteCustomerNews',
  async (newsId, { dispatch }) => {
    try {
      await api.customer().news.delete(newsId);
      await dispatch(fetchCustomersNews());
    } catch (error: any) {
      throw (error?.response?.data?.message);
    }
  },
);

export const addCustomerNews = createAsyncThunk<
void,
{ title: string, description: string, url?: string }
>(
  'customers/addCustomerNews',
  async (payload, { dispatch }) => {
    try {
      await api.customer().news.add(payload);
      await dispatch(fetchCustomersNews());
    } catch (error: any) {
      throw (error?.response?.data?.message);
    }
  },
);
