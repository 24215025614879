import { ALLCUSTOMERS } from '../../component/action/actionList';

const initialState = {
  allCustomersData: null,
  error: '',
  isLoading: false,
};

export default function allCustomersReducer(state = initialState, action) {
  switch (action.type) {
    case ALLCUSTOMERS.SUCCESS:
      return {
        ...state,
        allCustomersData: action.allCustomersData,
      };
    case ALLCUSTOMERS.ERROR:
      return {
        ...state,
        error: action.error,
      };
    case ALLCUSTOMERS.LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case ALLCUSTOMERS.RESET:
      return {
        ...state,
        isLoading: false,
        error: '',
        customerData: null,
      };
    default:
      return state;
  }
}
