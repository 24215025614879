import { combineReducers } from '@reduxjs/toolkit';
import customerReducer from './customerReducer';
import paymentReducer from './paymentReducer';
import customerProfilReducer from './customerProfilReducer';
import allCustomersReducer from './allCustomersReducer';
import endedMissionReducer from './endedMissionReducer';
import allEventsReducer from './allEventsReducer';
import providerReducer from './providerReducer';
import notificationReducer from './notificationReducer';
import unpaidReducer from './Monitoring/unpaidReducer';
import { reducer as planningMonitoringReducer } from './Monitoring/planning';
import { slice as customerSlice } from './customers';
import { slice as appSlice } from './app/app.slice';

export default combineReducers({
  customerReducer,
  paymentReducer,
  customerProfilReducer,
  allCustomersReducer,
  endedMissionReducer,
  allEventsReducer,
  providerReducer,
  notificationReducer,
  unpaidReducer,
  planningMonitoringReducer,
  [customerSlice.name]: customerSlice.reducer,
  [appSlice.name]: appSlice.reducer,
});
