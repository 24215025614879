import { createSlice } from '@reduxjs/toolkit';

import {
  fetchReccurences,
} from './app.thunks';

interface AppInitialState {
  reccurences: ConfListItem[];
}

const initialState: AppInitialState = {
  reccurences: [],
};

export const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    reset: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReccurences.fulfilled, (state, { payload }) => {
        state.reccurences = payload;
      });
  },
});

export const resetAppState = slice.actions.reset;
