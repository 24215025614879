import getConfig from 'next/config';
import fetch from 'isomorphic-unfetch';
import cookie from 'js-cookie';

const { publicRuntimeConfig } = getConfig();

export async function getConf(field, apiToken = null) {
  return (fetch(
    `${publicRuntimeConfig?.API_NOBO_URL}/manager/conf?name=${field}`,
    {
      method: 'get',
      headers: {
        'content-type': 'application/json',
        apiToken: apiToken || cookie.get('apiToken'),
      },
    },
  )
    .then((response) => response.json()).then((json) => {
      if (json.status !== 200) {
        return null;
      }
      return json.data;
    }).catch((e) => console.log(e))
  );
}

export async function putConf(field, credential) {
  return (fetch(
    `${publicRuntimeConfig?.API_NOBO_URL}/manager/conf?name=${field}`,
    {
      method: 'put',
      headers: {
        'content-type': 'application/json',
        apiToken: cookie.get('apiToken'),
      },
      body: JSON.stringify({
        conf: credential,
      }),
    },
  )
    .then((response) => response.json()).then((json) => {
      if (json.status !== 200) {
        return null;
      }
      return json;
    }).catch((e) => console.log(e))
  );
}
