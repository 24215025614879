import { EVENT } from '../../component/action/actionList';

const initialState = {
  events: null,
  error: '',
  isLoading: false,
};

export default function allEventsReducer(state = initialState, action) {
  switch (action.type) {
    case EVENT.SUCCESS:
      return {
        ...state,
        events: action.events,
      };
    case EVENT.ERROR:
      return {
        ...state,
        error: action.error,
      };
    case EVENT.LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    default:
      return state;
  }
}
