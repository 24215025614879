import { NOTIFICATION } from '../../component/action/actionList';

type State = {
  isOpen: boolean;
  variant: string;
  message: string;
};

const initialState: State = {
  isOpen: false,
  variant: '',
  message: '',
};

export default function notification(state = initialState, action: any): State {
  switch (action.type) {
    case NOTIFICATION.SET:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
}
