import { createSlice } from '@reduxjs/toolkit';

import {
  fetchCustomersNews,
  fetchCustomersSuggestions,
} from './customers.thunks';

interface CustomersInitialState {
  news: CustomerNews[];
  suggestions: CustomerSuggestion[];
}

const initialState: CustomersInitialState = {
  news: [],
  suggestions: [],
};

export const slice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    reset: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomersNews.fulfilled, (state, { payload }) => {
        state.news = payload;
      })
      .addCase(fetchCustomersSuggestions.fulfilled, (state, { payload }) => {
        state.suggestions = payload;
      });
  },
});

export const resetCustomersState = slice.actions.reset;
