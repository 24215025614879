import { PROVIDER } from '../../component/action/actionList';

const initialState = {
  resUpdate: null,
};

export default function providerReducer(state = initialState, action) {
  switch (action.type) {
    case PROVIDER.UPDATE_PASSWORD:
      return {
        ...state,
        resUpdate: action.payload,
      };

    default:
      return state;
  }
}
