import fetch from 'isomorphic-unfetch';
import getConfig from 'next/config';
import redirect from './redirect';

const { serverRuntimeConfig } = getConfig();

const isTokenValidRequest = async (apiToken) => fetch(`${serverRuntimeConfig?.API_NOBO_URL}/token/isValid/apiToken`, {
  method: 'get',
  headers: {
    'content-type': 'application/json',
    apiToken,
    who: 'manager',
  },
});

export async function checkToken(req, res) {
  const apiToken = await getApiToken(req);
  if (apiToken === null || typeof apiToken === 'undefined') return (redirect(res, '/connexion'));
  const tokenIsValidRes = await isTokenValidRequest(apiToken);
  const tokenIsValid = await tokenIsValidRes.json();
  if (tokenIsValid.status === 200) {
    return apiToken;
  }
}

export async function getApiToken(req) {
  if (req && req.headers && req.headers.cookie) {
    const cookies = req.headers.cookie.split(';');
    let apiToken = null;
    cookies.map((cookie) => {
      if (cookie.includes('apiToken')) {
        apiToken = cookie.split('=')[1];
      }
    });
    return apiToken;
  }
}
