import api from '@api';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchReccurences = createAsyncThunk(
  'app/fetchReccurences',
  async (payload, { getState }): Promise<ConfListItem[]> => {
    const { data: { data } } = await api.configuration.list('recurrence');
    return data;
  },
);
