import { PAYMENT } from '../../component/action/actionList';

const initialState = {
  paymentData: null,
  error: '',
  isLoading: false,
  cesuAdded: 0,
};

export default function paymentReducer(state = initialState, action) {
  switch (action.type) {
    case PAYMENT.SUCCESS:
      return {
        ...state,
        paymentData: action.paymentData,
      };
    case PAYMENT.ERROR:
      return {
        ...state,
        error: action.error,
      };
    case PAYMENT.LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case PAYMENT.RESET:
      return {
        ...state,
        error: '',
        paymentData: null,
      };
    case PAYMENT.ADD_CESU_POT:
      return {
        ...state,
        cesuAdded: state.cesuAdded + action.cesuAdded,
      };
    default:
      return state;
  }
}
