import { MISSION_END } from '../../component/action/actionList';

const initialState = {
  eMissions: null,
  error: '',
  isLoading: false,
};

export default function endedMissionReducer(state = initialState, action) {
  switch (action.type) {
    case MISSION_END.SUCCESS:
      return {
        ...state,
        eMissions: action.eMissions,
      };
    case MISSION_END.ERROR:
      return {
        ...state,
        error: action.error,
      };
    case MISSION_END.LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    default:
      return state;
  }
}
