import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';

import {
  fetchAvailableProviders,
  getEventWaiting,
  getEventWaitingStats,
} from './planning.thunks';

interface PlanningInitialState {
  stats: EventWaitingStats;
  events: EventWaiting[];
  lastStatsUpdate: string;
  lastEventsUpdate: string;
  start: string;
  end: string;
  filter: 'all' | 'recurrent' | 'punctual';
  currentNeeds: EventWaitingNeeds;
  availableProviders: EventWaitingAvailableProvider[];
  selectedEvent?: EventWaiting;
}

const initialState: PlanningInitialState = {
  stats: {
    recurrentBenefit: 0,
    punctualBenefit: 0,
    newCustomers: 0,
    doneThisMonth: 0,
  },
  events: [],
  lastStatsUpdate: '',
  lastEventsUpdate: '',
  start: moment().subtract(1, 'week').format('YYYY-MM-DD'),
  end: moment().format('YYYY-MM-DD'),
  filter: 'all',
  selectedEvent: undefined,
  currentNeeds: {
    availableDate: '',
    recurrence: '',
    hours: 0,
    slots: [],
    animals: 0,
    travelTimePtoC: '',
    travelTimeCtoC: '',
    eventWaitingId: 0,
  },
  availableProviders: [],
};

const planningMonitoringSlice = createSlice({
  name: 'planningMonitoring',
  initialState,
  reducers: {
    reset: (state) => {
      Object.assign(state, initialState);
    },
    setStart: (state, action) => {
      state.start = action.payload;
    },
    setEnd: (state, action) => {
      state.end = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setSelectedEvent: (state, action: PayloadAction<EventWaiting>) => {
      state.selectedEvent = action.payload;
    },
    setCurrentNeeds: (state, action: PayloadAction<PlanningInitialState['currentNeeds']>) => {
      state.currentNeeds = action.payload;
    },
    resetCurrentNeeds: (state) => {
      state.currentNeeds = initialState.currentNeeds;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEventWaiting.fulfilled, (state, { payload }) => {
        state.events = payload;
        state.lastEventsUpdate = moment().toISOString();
      })
      .addCase(getEventWaitingStats.fulfilled, (state, {
        payload: {
          recurrentBenefit,
          punctualBenefit,
          newCustomers,
          doneThisMonth,
        },
      }) => {
        state.stats.doneThisMonth = doneThisMonth;
        state.stats.newCustomers = newCustomers;
        state.stats.punctualBenefit = punctualBenefit;
        state.stats.recurrentBenefit = recurrentBenefit;
        state.lastStatsUpdate = moment().toISOString();
      })
      .addCase(fetchAvailableProviders.fulfilled, (state, { payload }) => {
        state.availableProviders = payload;
      });
  },
});

export const {
  reset,
  setFilter,
  setStart,
  setEnd,
  setSelectedEvent,
  setCurrentNeeds,
} = planningMonitoringSlice.actions;
export const { reducer } = planningMonitoringSlice;
export type PlanningMonitoringState = ReturnType<typeof planningMonitoringSlice.reducer>;
