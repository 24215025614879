import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '@api';

export const getEventWaitingStats = createAsyncThunk(
  'monitoring/planning/getEventWaitingStats',
  async () => {
    const { data: { data } } = await api.event.waiting.stats();
    return data;
  },
);
export const getEventWaiting = createAsyncThunk(
  'monitoring/planning/getEventWaiting',
  async (payload, { getState, dispatch }): Promise<EventWaiting[]> => {
    const { planningMonitoringReducer: { start, end, filter } } = getState() as any;
    const { data: { data } } = await api.event.waiting.get(start, end, filter);
    await dispatch(getEventWaitingStats());
    return data;
  },
);

export const fetchAvailableProviders = createAsyncThunk(
  'monitoring/planning/fetchAvailableProviders',
  async (payload, { getState }): Promise<EventWaitingAvailableProvider[]> => {
    const { planningMonitoringReducer: { currentNeeds } } = getState() as any;
    const { data: { data } } = await api.event.waiting.fetchAvailableProviders(currentNeeds);
    return data;
  },
);
