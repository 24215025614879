import { CUSTOMER } from '../../component/action/actionList';

const initialState = {
  customerData: null,
  error: '',
  isLoading: false,
};

export default function customerReducer(state = initialState, action) {
  switch (action.type) {
    case CUSTOMER.SUCCESS:
      return {
        ...state,
        customerData: action.customerData,
      };
    case CUSTOMER.ERROR:
      return {
        ...state,
        error: action.error,
      };
    case CUSTOMER.LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case CUSTOMER.RESET:
      return {
        ...state,
        isLoading: false,
        error: '',
        customerData: null,
      };
    default:
      return state;
  }
}
