/* eslint-disable no-underscore-dangle */

import { SheetsRegistry } from 'jss';
import { createMuiTheme, createGenerateClassName } from '@material-ui/core/styles';

const oldPalette = {
  primary: {
    light: '#c6ebe7',
    main: '#68CBBF',
    dark: '#3bab9e',
  },
  secondary: {
    light: '#f8c1ba',
    main: '#f18679',
    dark: '#ee6e5d'
  },
};

const updatedPalette = {
  primary: {
    light: '#B7CCBD',
    main: '#133426',
    dark: '#0a1a13',
  },
  secondary: {
    light: '#ede0cd',
    main: '#e5d2b7',
    dark: '#a09380'
  },
}

// A theme with custom primary and secondary color.
// It's optional.
const theme = createMuiTheme({
  palette: updatedPalette,
  typography: {
    useNextVariants: true,
  },
});

function createPageContext() {
  return {
    theme,
    // This is needed in order to deduplicate the injection of CSS in the page.
    sheetsManager: new Map(),
    // This is needed in order to inject the critical CSS.
    sheetsRegistry: new SheetsRegistry(),
    // The standard class name generator.
    generateClassName: createGenerateClassName(),
  };
}

let pageContext;

export default function getPageContext() {
  // Make sure to create a new context for every server-side request so that data
  // isn't shared between connections (which would be bad).
  if (!process.browser) {
    return createPageContext();
  }

  // Reuse context on the client-side.
  if (!pageContext) {
    pageContext = createPageContext();
  }

  return pageContext;
}
