import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { createWrapper } from 'next-redux-wrapper';
import combineReducers from './reducer/combinedReducer';

const rootReducer = combineReducers;

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
  devTools: !['staging', 'production'].includes(process.env.NODE_ENV),
});

const makeStore = () => store;

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export const wrapper = createWrapper(makeStore, {
  debug: false,
});
